import React, { useEffect, useState } from "react";
import { DialogActions, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Switch, styled } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Android12Switch from "../Android12Switch";

interface MarkAsCompleteProps {
    id?: number,
    status?: number,
    store: any
}

const MarkAsComplete: React.FC<MarkAsCompleteProps> = ({ id, status, store }) => {
    const [open, setOpen] = React.useState(false);
    const [actionLoading, setactionLoading] = useState(false);
    const [checkedValue, setCheckedValue] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const markAsComplete = () => {
        id && store.markAsComplete(id).then((_elm: any) => {
            setCheckedValue(!checkedValue)
            handleClose()
            setactionLoading(false)
        })

    }
    useEffect(() => {
        status === 1 ? setCheckedValue(false) : setCheckedValue(true)
    }, [])


    return (
        <div>
            {
                <div className="align-items-center flex flex-row justify-content-center">
                    <p className="font-weight-bold margin-remove-bottom">Mark as Complete</p>
                    <Android12Switch aria-label='Mark as Complete' checked={checkedValue} onChange={
                        () => {
                            if (status === 1) {
                                setactionLoading(true)
                                handleClickOpen()
                            }
                            else{
                                setactionLoading(true)
                                markAsComplete()
                            }

                        }
                    } />
                </div>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="complete-dialog-title"
            >
                <DialogTitle id="complete-dialog-title">
                    Complete the Module?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to mark this module as complete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleClose()
                        setCheckedValue(false)
                    }
                    }>
                        No
                    </Button>
                    <Button onClick={() => {

                        markAsComplete()
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )

}
export default MarkAsComplete