import React, { useEffect, useState } from "react";
import { DialogActions, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Switch, styled } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Android12Switch from "../Android12Switch";

interface CollectedFeedbackProps {
    id?: number,
    status?: boolean,
    store: any
}

const CollectedFeedback: React.FC<CollectedFeedbackProps> = ({ id, status, store }) => {
    const [open, setOpen] = React.useState(false);
    const [actionLoading, setactionLoading] = useState(false);
    const [checkedValue, setCheckedValue] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const collectedFeedback = () => {
        id && store.collectedFeedback(id).then((_elm: any) => {
            setCheckedValue(!checkedValue)
            handleClose()
            setactionLoading(false)
        })

    }
    useEffect(() => {
        status === false ? setCheckedValue(false) : setCheckedValue(true)
    }, [])

    const titleText = "Mid-module Feedback Complete";

    return (
        <div>
            {
                <div className="align-items-center flex flex-row justify-content-center">
                    <p className="font-weight-bold margin-remove-bottom">{titleText}</p>
                    <Android12Switch aria-label={titleText} checked={checkedValue} onChange={
                        () => {
                            if (status === false) {
                                setactionLoading(true)
                                handleClickOpen()
                            }
                            else{
                                setactionLoading(true)
                                collectedFeedback()
                            }

                        }
                    } />
                </div>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="feedback-complete-dialog-title"
            >
                <DialogTitle id="feedback-complete-dialog-title">
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to set this mid-module feedback as complete? 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleClose()
                        setCheckedValue(false)
                    }
                    }>
                        No
                    </Button>
                    <Button onClick={() => {

                        collectedFeedback()
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )

}
export default CollectedFeedback